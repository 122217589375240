import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class ArmazenamentoService {
    constructor(
        private router: Router
    ) { }

    obterItem(nome) {
        if (this.obterModalidadeLogin() === 'session') {
            return sessionStorage.getItem(nome)
        
        } else {
            return localStorage.getItem(nome)

        }
    }

    definirItem(nome, valor) {
        if (this.obterModalidadeLogin() === 'session') {
            sessionStorage.setItem(nome, valor)

        } else {
            localStorage.setItem(nome, valor)

        }
    }

    obterModalidadeLogin() {
        let modalidadeLogin

        modalidadeLogin = localStorage.getItem('modalidadeLogin')

        /**
         * Se foi modificado o valor da modalidade do login, por segurança, limpamos o
         * "local" e o "session" storage e forçamos o usuário a fazer login novamente.
         */
        if (modalidadeLogin !== 'session' && modalidadeLogin !== 'local') {
            sessionStorage.clear()
            localStorage.clear()
            localStorage.setItem('modalidadeLogin', 'session')
            this.router.navigate(['/login'])
        }

        return modalidadeLogin
    }
}
