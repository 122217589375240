import { Injectable } from '@angular/core';
import { AppSettings } from '../../appconfig/app-settings';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NSConfigService {
    constructor(
        private _login: LoginService,
        private _toastr: ToastrService
    ) { }

    private ativarModoHomologacao(origem): boolean {
        AppSettings.URL_GESTOR = AppSettings.URL_GESTOR_HOMOLOGACAO;
        AppSettings.URL_PAINEL = AppSettings.URL_PAINEL_HOMOLOGACAO;
        AppSettings.AMBIENTE   = 2;

        if (origem === 'login') {
            this._login.setUrlGestor(AppSettings.URL_GESTOR);
        }

        this._toastr.success('Alterado para modo de homologação');

        return true;
    }   

    private ativarModoProducao(origem): boolean {
        AppSettings.URL_GESTOR = AppSettings.URL_GESTOR_PRODUCAO;
        AppSettings.URL_PAINEL = AppSettings.URL_PAINEL_PRODUCAO;
        AppSettings.AMBIENTE   = 3;

        if (origem === 'login') {
            this._login.setUrlGestor(AppSettings.URL_GESTOR);
        }

        this._toastr.success('Alterado para modo de produção');
        
        return true;
    }

    /** 
     * @param comando Comando de configuração a ser executado
     * @param origem Nome do component que executou o comando
     */
    public executarComando(comando, origem): any {
        if (AppSettings.AMBIENTE == 2 || AppSettings.AMBIENTE == 3) {
            if (comando === '/nsconfig.ambiente.hml') {
                return this.ativarModoHomologacao(origem);

            } else if (comando === '/nsconfig.ambiente.prd') {
                return this.ativarModoProducao(origem);
            }
        }

        return null;
    }
}
