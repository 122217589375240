export class AppSettings {
    static URL_GESTOR: string;
    static URL_GESTOR_PRODUCAO = 'https://gestorapi.ns.eti.br/';
    static URL_GESTOR_HOMOLOGACAO = 'https://gestorapihml.ns.eti.br/';
    static TOKEN_GESTOR = 'ns1234token';

    static URL_PAINEL: string;
    static URL_PAINEL_PRODUCAO = 'https://painelapinet.ns.eti.br/';
    static URL_PAINEL_HOMOLOGACAO = 'https://painelapinethml.ns.eti.br/';

    static URL_PAINEL_ANTIGO = 'https://painelapi.ns.eti.br/';

    static URL_CADASTRO_USUARIO: string;
    static URL_CADASTRO_USUARIO_PRODUCAO = 'https://cadastro.nstecnologia.com.br/usuario/#/novo/';
    static URL_CADASTRO_USUARIO_HOMOLOGACAO = 'https://cadastrohml.nstecnologia.com.br/usuario/#/novo/';

    static URL_API_STORAGE: string;
    static URL_API_STORAGE_PRODUCAO = "https://storage.ns.eti.br/";
    static URL_API_STORAGE_HOMOLOGACAO = "https://hml-ns-storageapi.azurewebsites.net/";

    static AMBIENTE = 0;

    private loading = false;

    constructor() {}

    getLoading() {
        return this.loading;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }
}