import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { ArmazenamentoService } from '../services/armazenamento/armazenamento.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private armazenamentoS: ArmazenamentoService,
        private router: Router
    ) { }

    canActivate() {
        let token;

        try {
            let userAtual = JSON.parse(this.armazenamentoS.obterItem('userAtual'));
            token = userAtual['token'];
        } catch (error) {
            token = '';
        }

        if ((this.armazenamentoS.obterItem('isLoggedin') == 'true') && (token != '')) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
