import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './shared/guard/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { LoginService } from './shared/services/login/login.service';
import { HttpClientModule } from '@angular/common/http';
import { NSConfigService } from './shared/services/nsconfig/nsconfig.service';
import { ArmazenamentoService } from './shared/services/armazenamento/armazenamento.service';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        GuidedTourModule,
        ToastrModule.forRoot({

        }),
        AppRoutingModule
    ],
    providers: [AuthGuard, LoginService, ArmazenamentoService, NSConfigService, GuidedTourService],
    bootstrap: [AppComponent]
})
export class AppModule { }
