import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { AppSettings } from './app/shared/appconfig/app-settings';

if (environment.production) {
  enableProdMode();
  AppSettings.URL_GESTOR = AppSettings.URL_GESTOR_PRODUCAO;
  AppSettings.URL_PAINEL = AppSettings.URL_PAINEL_PRODUCAO;
  AppSettings.URL_CADASTRO_USUARIO = AppSettings.URL_CADASTRO_USUARIO_PRODUCAO;
  AppSettings.URL_API_STORAGE = AppSettings.URL_API_STORAGE_PRODUCAO;
  AppSettings.AMBIENTE   = 1;

  console.log('PRODUÇÃO');

} else {
  AppSettings.URL_GESTOR = AppSettings.URL_GESTOR_HOMOLOGACAO;
  AppSettings.URL_PAINEL = AppSettings.URL_PAINEL_HOMOLOGACAO;
  AppSettings.URL_CADASTRO_USUARIO = AppSettings.URL_CADASTRO_USUARIO_HOMOLOGACAO;
  AppSettings.URL_API_STORAGE = AppSettings.URL_API_STORAGE_HOMOLOGACAO;
  AppSettings.AMBIENTE   = 2;

  console.log('HOMOLOGAÇÃO');

  // AppSettings.URL_GESTOR = AppSettings.URL_GESTOR_PRODUCAO;
  // AppSettings.URL_PAINEL = AppSettings.URL_PAINEL_PRODUCAO;
  // AppSettings.AMBIENTE   = 3; // Este ambiente indica "PERIGO", caso o desenvolvedor esqueça de trocar para URL de homologação antes do build.

  // console.log('PRODUÇÃO');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
