import { Injectable } from '@angular/core';
import { AppSettings } from '../../appconfig/app-settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ArmazenamentoService } from '../armazenamento/armazenamento.service';

@Injectable()
export class LoginService {
    private URL_GESTOR = AppSettings.URL_GESTOR;

    constructor(
        private armazenamentoS: ArmazenamentoService,
        private _http: HttpClient
    ) { }

    logar(_usuario: string, _senha: string) {
        let dados = {
            usuario: _usuario,
            senha: _senha
        };

        return this._http.post(this.URL_GESTOR + 'v1/login/logar?painel=true', dados);
    }

    loginRedes(rede, token) {
        const dados = {
            rede: rede,
            token: token
        };
        
        return this._http.post(this.URL_GESTOR + 'api/v1/login/redes?painel=true', dados);
    }

    logout() {
        // Remove usuário do sessionStorage para fazer logout
        this.armazenamentoS.definirItem('isLoggedin', 'false');
        localStorage.removeItem('userAtual');
    }

    resetarSenha(login, token = 'ns1234token') {
        let dados = {
            login: login
        };

        return this._http.post(this.URL_GESTOR + 'v1/login/ResetarSenha', dados, {
            headers: new HttpHeaders().set('token-painel-api', token)
        });
    }

    setUrlGestor(url) {
        this.URL_GESTOR = url;
    }

}
